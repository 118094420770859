







































import type { Link, Picture } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'

import Carousel from '@/components/Carousel.vue'

interface CtaSocialProjects {
  title: string
  htmltext: string
  link: Link
  pictures: Picture[]
}

export default defineComponent({
  name: 'CtaSocialProjects',
  components: { Carousel },
  props: {
    content: {
      type: Object as PropType<CtaSocialProjects>,
      required: true,
    },
  },

  setup(props) {
    const rootEl = ref<HTMLElement | null>(null)

    // Push event to datalayer on click
    const onClick = () => {
      if (!rootEl.value) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel: props.content.link.label,
        ctaType: 'social_projects',
        ctaUrl: props.content.link.url,
      }

      pushCta(layer, rootEl.value)
    }

    return {
      rootEl,
      onClick,
    }
  },
})
